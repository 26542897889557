import React from "react"
import PropTypes from "prop-types"
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LineShareButton,
  LineIcon,
  HatenaShareButton,
  HatenaIcon,
} from "react-share"

const SocialLinks = ({ title, url }) => {
  return (
    <div className="socialButton">
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={32} />
      </TwitterShareButton>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={32} />
      </FacebookShareButton>
      <LineShareButton url={url} quote={title}>
        <LineIcon size={32} />
      </LineShareButton>
      <HatenaShareButton url={url} quote={title}>
        <HatenaIcon size={32} />
      </HatenaShareButton>
    </div>
  )
}

SocialLinks.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default SocialLinks
